<template>
  <div>
     <div class="container" style="margin-bottom: 1rem">
      <el-descriptions title="场馆详情" v-if="spaceInfo.id">
        <el-descriptions-item label="场馆名称">
          {{ spaceInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item label="场馆地址">
          {{ spaceInfo.address }}
        </el-descriptions-item>
        <el-descriptions-item label="负责人">
          {{ spaceInfo.contacts }}
        </el-descriptions-item>
        <el-descriptions-item label="联系电话">
          {{ spaceInfo.contact_telephone }}
        </el-descriptions-item>
        <el-descriptions-item label="经营类目">
          {{ spaceInfo.categories_child }}
        </el-descriptions-item>
         <el-descriptions-item label="营业时间">
          {{ spaceInfo.opentime }}
        </el-descriptions-item>
         <el-descriptions-item label="信息">
          {{ getTags() }}
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <span v-if="spaceInfo.status == 1" style="color: #23c3c4"
            ><span> 待审核</span>
          </span>
          <span v-else-if="spaceInfo.status == 2" style="color: #32cd32"
            >已通过</span
          >
          <span v-else-if="spaceInfo.status == 3" style="color: #ff6262"
            >已拒绝</span
          >
          <span v-else-if="spaceInfo.status == 4" style="color: #ff6262"
            >注销</span
          >
          <span v-else-if="spaceInfo.status == 5" style="color: #ff6262"
            >冻结</span
          >
          <el-button @click="updateStatus(2)" type="success" v-if="spaceInfo.status == 1"
            >通过审核</el-button
          >
          <el-button @click="openRefuse" type="danger" v-if="spaceInfo.status == 1"
            >审核拒绝</el-button
          >
        </el-descriptions-item>

        <el-descriptions-item label="场馆logo">
          <el-image
            style="width: 150px; height: 150px"
            :src="spaceInfo.logo"
            fit="cover"
            :preview-src-list="[spaceInfo.logo]"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="营业执照">
          <el-image
            v-for="(pic, index) in spaceInfo.support_means.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[spaceInfo.support_means.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>
         <el-descriptions-item label="运营资质">
          <el-image
            v-for="(pic, index) in spaceInfo.qualifications_pic.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[spaceInfo.qualifications_pic.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="场馆门面">
          <el-image
            v-for="(pic, index) in spaceInfo.facade_view.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[spaceInfo.facade_view.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="介绍图片">
          <el-image
            v-for="(pic, index) in spaceInfo.introduction_pic.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="[spaceInfo.introduction_pic.split(',')]"
            :key="index"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
    </div>

     <refusePop :show="refuseShow" @confirm="confirm"></refusePop>
  </div>
</template>

<script>
import refusePop from '../../components/refusePop/refusePop.vue';
export default {
   components:{
      
      refusePop
  },
  data() {
    return {
      refuseShow:false,
      id:'',
      spaceInfo:{}
    };
  },

  mounted() {
    this.id = this.$route.query.id
    this.getData()
  },

  methods: {
    openRefuse(){
        this.refuseShow = true
    },
    confirm(text){
         this.$post('/shared/sharedSpace/update',{id:this.id,status:5,refuse_contect:text}).then(res=>{
        this.spaceInfo.status = 3
        })
    },
     getTags(){
      let tag = JSON.parse(this.spaceInfo.tags || "{}")
      let vs =  Object.entries(tag)
      let text = ''
      vs.forEach(item=>{
        let l = item[0] + ':' + item[1] + ' '
        text = text + l
      })
			return text
    },
     updateStatus(status){
        this.$post('/shared/sharedSpace/update',{id:this.id,status}).then(res=>{
          this.$message('成功')
          this.spaceInfo.status = status
        })
    },
    getData(){
         this.$get('/shared/sharedSpace/findById',{id:this.id}).then(res=>{
            this.spaceInfo = res.data.data
        })
    }
  },
};
</script>